import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { headerBgState } from "../../types/SliceTypes/headerBgSlice";

const initialState: headerBgState = {
  backgroundColor: "bg-transparent",
};

const headerBgSlice = createSlice({
  name: "headerBg",

  initialState,

  reducers: {
    setBg: (state, action: PayloadAction<string>) => {
      state.backgroundColor = action.payload;
    },
  },
});

export const { setBg } = headerBgSlice.actions;
export default headerBgSlice.reducer;
