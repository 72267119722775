import { useAppSelector } from "../../store/hooks";

export const MobileMenuButton = ({
  showMenu,
  setShowMenu,
}: {
  showMenu: boolean;
  setShowMenu: Function;
}) => {
  const headerBg = useAppSelector((state) => state.headerBg.backgroundColor);

  return (
    <button
      className="flex bg-transparent z-[20] rounded-lg w-[28px] min-w-[28px] h-8 flex-col items-end p-1 outline-0 border-0 justify-evenly tables:hidden"
      data-testid="mobile-menu-button"
      onClick={() => setShowMenu(!showMenu)}
    >
      <span
        className={`h-[2px] w-[20px] block rounded-lg transition-all duration-500 ${
          (showMenu && "bg-white") ||
          (headerBg === "bg-transparent" ? "bg-white" : "bg-black")
        }
            ${showMenu ? "absolute rotate-45" : "relative rotate-0"}`}
      ></span>
      <span
        className={`h-[2px] block rounded-lg transition-all duration-500 ${
          (showMenu && "bg-white") ||
          (headerBg === "bg-transparent" ? "bg-white" : "bg-black")
        }
        ${
          showMenu
            ? "absolute rotate-[135deg] w-[20px]"
            : "relative rotate-0 w-[20px]"
        }`}
      ></span>
    </button>
  );
};
