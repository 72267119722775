import { motion } from "framer-motion";
import Logo from "../../images/Logos/sales-consult.jpg";
import HeaderLogo from "../../images/Header/sales-consult-removebg-preview.png";
import Bg1 from "../../images/BrandsBackground/sales-consult-1.jpg";
import Bg2 from "../../images/BrandsBackground/sales-consult.jpg";
import mainPhoto from "../../images/BrandsBackground/sales-consult-main.jpg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import { useTranslation } from "react-i18next";
import {
  arrAnimation,
  branchAnimationLeft,
  branchAnimationRight,
  mainAnimation,
} from "../../animations";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";

export const SalesConsult = () => {
  const footerInfo = {
    formURL: "",
    phoneNumber: "+38 (097)-072-00-12",
    email: "viiolla0101@gmail.com ",
    inst: "",
    telega: "",
    address: "",
    viber: "0933762621",
  };
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const isDesktop = screenWidth > 1100;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <main>
      <Header
        mainColor={`#5a65f7`}
        imgSrc={HeaderLogo}
        branchRoute="/sales-consult"
      />
      <section className="relative h-[75vh]">
        <div
          className="w-full h-full absolute top-0 left-0"
          style={{
            backgroundImage: `url(${mainPhoto})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="container__standart pt-[25vh] flex items-center flex-col"
          >
            <motion.h1
              variants={mainAnimation}
              className="text-center text-white font-bold text-5xl mx-[2rem] mainPageLG:text-6xl"
            >
              {t("SalesConsult.name")}
            </motion.h1>
            <button
              className={`transition__hover mt-[6.25rem] bg-[#5a65f7] text-white px-[10px] h-12 rounded-lg hover:bg-orange-400`}
              onClick={() => {
                dispatch(setActive(true));
              }}
            >
              {t("mainPage.consultation")}
            </button>
          </motion.div>
        </div>
      </section>
      <section>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 max-w-[60%] upMD:max-w-[40%] mainPageLG:max-w-[500px]"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="mb-[1.6rem] text-center md:text-left"
              >
                {t("SalesConsult.block1.h1")}
              </motion.h5>
              <motion.p
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="mb-[1.6rem] font-semibold"
              >
                {t("SalesConsult.block1.ul")}
              </motion.p>
              <motion.ul
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                className="list-disc ml-[2.5rem] font-semibold"
              >
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li2")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li3")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li4")}
                </motion.li>
                <motion.li
                  custom={5}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li5")}
                </motion.li>
                <motion.li
                  custom={6}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li6")}
                </motion.li>
                <motion.li
                  custom={7}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li7")}
                </motion.li>
                <motion.li
                  custom={8}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block1.li8")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="bg-[#5a65f7]">
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="relative w-full max-w-[1200px] text-white leading-7 flex items-center">
            <img
              className=" object-cover hidden pl-[2.5rem] w-[33%] h-full mainPageLG:block"
              alt="transperent-logo"
              src={Bg1}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              className="mainPageLG:ml-[10%]"
            >
              <motion.p
                variants={branchAnimationRight}
                className="font-semibold"
              >
                {t("SalesConsult.block2.p1")}
              </motion.p>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="w-full max-w-[1200px] text-[#1a202c] leading-7">
            <img
              className="opacity-20 absolute left-[50%] -translate-x-1/2 -translate-y-6 max-w-[60%] upMD:max-w-[40%] mainPageLG:-translate-y-24 mainPageLG:max-w-[500px]"
              alt="transperent-logo"
              src={Logo}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:mr-[30%]"
            >
              <motion.h5
                variants={
                  isDesktop ? branchAnimationLeft : branchAnimationRight
                }
                className="mb-[1.6rem] text-center md:text-left"
              >
                {t("SalesConsult.block3.h1")}
              </motion.h5>
              <motion.ul
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                className="list-disc ml-[2.5rem] font-semibold"
              >
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li2")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li3")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li4")}
                </motion.li>
                <motion.li
                  custom={5}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li5")}
                </motion.li>
                <motion.li
                  custom={6}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li6")}
                </motion.li>
                <motion.li
                  custom={7}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block3.li7")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
      <section className="bg-[#5a65f7]">
        <div className="container__standart flex items-center flex-col px-[1.5rem] py-[3rem] gap-y-[3rem] upMD:gap-y-[5rem] upMD:px-[2.5rem] upMD:py-[6rem] mainPageLG:gap-y-[7rem]">
          <div className="relative w-full max-w-[1200px] text-white leading-7 flex items-center">
            <img
              className="object-cover hidden pl-[2.5rem] w-[33%] h-full mainPageLG:block"
              alt="transperent-logo"
              src={Bg2}
            />
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="mainPageLG:ml-[10%]"
            >
              <motion.h5
                variants={branchAnimationRight}
                className="mb-[1.6rem] text-center md:text-left"
              >
                {t("SalesConsult.block4.h1")}
              </motion.h5>
              <motion.ul
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.1, once: true }}
                className="list-disc ml-[2.5rem] font-semibold"
              >
                <motion.li
                  custom={1}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block4.li1")}
                </motion.li>
                <motion.li
                  custom={2}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block4.li2")}
                </motion.li>
                <motion.li
                  custom={3}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block4.li3")}
                </motion.li>
                <motion.li
                  custom={4}
                  variants={arrAnimation}
                  className="mb-[0.5rem]"
                >
                  {t("SalesConsult.block4.li4")}
                </motion.li>
              </motion.ul>
            </motion.div>
          </div>
        </div>
      </section>
      <section>
        <Footer mainColor="#5a65f7" info={footerInfo} />
      </section>
    </main>
  );
};
