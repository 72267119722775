import "./App.css";
import { PagesRouting } from "./pages/PagesRouting/PagesRouting";

function App() {
  return (
    <>
      <PagesRouting />
    </>
  );
}

export default App;
