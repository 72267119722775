import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { careerInfoType, modalState } from "../../types/SliceTypes/modalState";

const initialState: modalState = {
  active: false,
  careerInfo: { title: "", text: "" },
};

const modalSlice = createSlice({
  name: "modal",

  initialState,

  reducers: {
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
    setCareerInfo: (state, action: PayloadAction<careerInfoType>) => {
      state.careerInfo = action.payload;
    },
  },
});

export const { setActive, setCareerInfo } = modalSlice.actions;
export default modalSlice.reducer;
