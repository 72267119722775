import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import modalSlice from "./slices/modalSlice";
import localeSlice from "./slices/localeSlice";
import headerBgSlice from "./slices/headerBgSlice";
import screenWidthSlice from "./slices/screenWidthSlice";

export const store = configureStore({
  reducer: {
    locale: localeSlice,
    modal: modalSlice,
    headerBg: headerBgSlice,
    screenWidth: screenWidthSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
