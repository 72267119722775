import { MCareerCard } from "../../components/CareerСard/CareerCard";
import StartCompanyLogo from "../../images/Logos/star-company-logo.png";
import MeediaCompanyLogo from "../../images/Logos/media-company-logo.png";
import TakeProfitLogo from "../../images/Logos/take-profit-logo-bg.png";
import BMPLogo from "../../images/Logos/bmp.jpg";
import ModernMarketingLogo from "../../images/Logos/modern.png";
import ATechLogo from "../../images/Logos/ATech.jpg";
import SalesConsultLogo from "../../images/Logos/sales-consult.jpg";
import mainPhoto from "../../images/MainPhotos/mainPhotoGrey.jpg";
import laptopPhoto from "../../images/MainPhotos/laptop.jpg";
import second from "../../images/MainPhotos/second.jpg";
import grey from "../../images/MainPhotos/grey.jpg";
import dicaprio from "../../images/MainPhotos/dicaprio.jpg";
import cat from "../../images/MainPhotos/cat.jpg";
import whatWeDo from "../../images/MainPhotos/whatWeDo.jpg";
import redCrossImg from "../../images/MainPhotos/redCross.png";
import numberBackground from "../../images/MainPhotos/odessa.jpg";
import whyWe from "../../images/MainPhotos/whyWe.jpg";
import { useAppDispatch } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import { Carousel } from "../../components/Carousel/Carousel";
import { motion } from "framer-motion";
import { arrAnimation, mainAnimation, titleAnimation } from "../../animations";
import { RedCross } from "../../components/RedCross/RedCross";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import HeaderLogo from "../../images/Header/Logo.png";

export const MainPage = () => {
  const brands = [
    { logo: StartCompanyLogo, route: "/star-company" },
    { logo: MeediaCompanyLogo, route: "/media-company" },
    { logo: TakeProfitLogo, route: "/take-profit" },
    { logo: BMPLogo, route: "/general-market" },
    { logo: ModernMarketingLogo, route: "/modern-marketing-of-ukraine" },
    { logo: ATechLogo, route: "/a-tech" },
    { logo: SalesConsultLogo, route: "/sales-consult" },
  ];
  const footerInfo = {
    formURL: "",
    phoneNumber: "+38 (095)-002-07-48",
    email: "tamararudnuk@gmail.com",
    inst: "",
    telega: "hrarina_q",
    viber: "",
    address: "",
  };
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <main>
      <Header mainColor="#150da3" imgSrc={HeaderLogo} branchRoute="/" />
      <section className="relative h-[100vh] sectionx">
        <div
          className="w-full h-full absolute top-0 left-0"
          style={{
            backgroundImage: `url(${mainPhoto})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="container__standart pt-[25vh] flex items-center flex-col"
          >
            <motion.h1
              className="text-[2.2rem] mainPageLG:text-[4.375rem]"
              variants={mainAnimation}
            >
              {t("mainPage.greeting")}!
            </motion.h1>
            <motion.p
              variants={mainAnimation}
              className="box-shadow w-[90%] py-5 rounded-[2.5rem] xxsm:w-[24rem] mt-10 text-white text-[1.1rem] xxsm:text-[1.2rem] font-medium text-center leading-[1.75rem] xxsm:box-shadow ssss:leading-[3.75rem] bg-[#00000059] mainPageLG:w-[60rem] xl:text-4xl md:w-[44rem] md:text-[1.7rem] xl:leading-[5.5rem]"
            >
              {t("mainPage.mainText")}
            </motion.p>
            <button
              className="transition__hover mt-[2.5rem] md:mt-[6.25rem] bg-[#150da3] text-white p-[10px] rounded-lg hover:bg-orange-400"
              onClick={() => {
                dispatch(setActive(true));
              }}
            >
              {t("mainPage.consultation")}
            </button>
          </motion.div>
        </div>
      </section>
      <section>
        <div className="w-full h-[39rem] md:h-[51.563rem] bg-[#d1b3c5]">
          <Element
            name="aboutUS"
            className="container__standart flex justify-center h-full pt-[2.5rem] xxsm:pt-[3.5rem] md:pt-[8.5rem] mainPageLG:pt-[5.5rem]"
          >
            <div className="hidden md:block">
              <img
                className="h-[65%] w-30% mainPageLG:h-[80%]"
                src={laptopPhoto}
                alt="#"
              />
            </div>
            <div className="w-[25rem] h-fit bg-[#d8ccf6] px-4 xxsm:px-10 py-5 mt-[3.5rem] md:ml-[-3.125rem] md:mt-[6.25rem]">
              <h2 className="mb-4 mainPageLG:mb-10">
                {t("mainPage.aboutUs.aboutUsTitle")}
              </h2>
              <div className="text-[1rem] xl:[&>*]:text-lg">
                <p className="mb-4 text-center mainPageLG:mb-10 md:text-left">
                  {t("mainPage.aboutUs.aboutUsp1")}
                  <span className="font-extrabold">
                    {t("mainPage.aboutUs.aboutUsSpan1")}
                  </span>
                  {t("mainPage.aboutUs.aboutUsp1AfterFirstSpan")}
                  <span className="font-extrabold">
                    {t("mainPage.aboutUs.aboutUsSpan2")}
                  </span>
                </p>
                <p className="text-center md:text-left">
                  {t("mainPage.aboutUs.aboutUsp2")}
                  <span className="font-extrabold">
                    {t("mainPage.aboutUs.aboutUsp2Span1")}
                  </span>
                  {t("mainPage.aboutUs.aboutUsp2AfterFirstSpan")}
                  <span className="font-extrabold">
                    {t("mainPage.aboutUs.aboutUsp2Span2")}
                  </span>
                </p>
              </div>
            </div>
          </Element>
        </div>
      </section>
      <section
        style={{
          backgroundImage: `url(${whyWe})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      // className="bg-[#d8ccf6]"
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.1, once: true }}
          className="container__standart flex flex-col justify-center py-[3.5rem] xxsm:py-[7.5rem] px-5"
        >
          <motion.div
            variants={titleAnimation}
            className="text-center items-center text-white"
          >
            <h2 className="text-white">{t("mainPage.whyWe.title")}</h2>
            <p className="text-white text-lg ssss:text-2xl xl:text-3xl">
              {t("mainPage.whyWe.underTitle")}
            </p>
          </motion.div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="flex flex-col [&>*]:my-2 [&>*]:flex [&>*]:bg-[#f1dfeb] [&>*]:items-center [&>*]:text-lg [&>*]:font-medium  w-full  [&>*]:p-[1.25rem]  [&>*]:rounded-3xl [&>*]:bg-[1.25rem] mt-20 
            mainPageLG:flex-row mainPageLG:[&>*]:w-[30%] mainPageLG:[&>*]:mx-[1.25rem] mainPageLG:[&>*]:my-0"
          >
            <motion.div
              custom={1}
              variants={arrAnimation}
              className="box-shadow w-full text-center md:text-left"
            >
              {t("mainPage.whyWe.block1")}
            </motion.div>
            <motion.div
              custom={2}
              variants={arrAnimation}
              className="box-shadow bg-[#eec288] text-center md:text-left"
            >
              {t("mainPage.whyWe.block2")}
            </motion.div>
            <motion.div
              custom={3}
              variants={arrAnimation}
              className="box-shadow bg-[#bdf2bb] text-center md:text-left"
            >
              {t("mainPage.whyWe.block3")}
            </motion.div>
          </motion.div>
        </motion.div>
      </section>
      <section className="bg-[#fff]">
        <Element
          name="branches"
          className="container__standart flex flex-col items-center pt-[3.5rem] xxsm:pt-[7.5rem]"
        >
          <motion.h2
            initial="hidden"
            whileInView="visible"
            variants={titleAnimation}
            viewport={{ amount: 0.1, once: true }}
            className="text-[2.7rem] mainPageLG:text-[3.75rem]"
          >
            {t("mainPage.ourBranches")}
          </motion.h2>
        </Element>
        <div className="py-[5.5rem]">
          <Carousel brands={brands} />
        </div>
      </section>
      <section className="py-[3.5rem] xxsm:py-[7.5rem] section__branches-gradient overflow-hidden">
        <Element name="ourServices" className="container__standart px-5">
          <div>
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              variants={titleAnimation}
              className="text-center text-[2.7rem] mainPageLG:text-[3.75rem]"
            >
              {t("mainPage.ourServices.title")}
            </motion.h2>
          </div>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="mt-10 xxsm:mt-20"
          >
            <div>
              <motion.div
                custom={1}
                variants={arrAnimation}
                className="flex flex-col mb-8 relative items-center md:flex-row md:h-[20rem]"
              >
                <img
                  className="h-full max-h-[390px] brightness-50 max-w-full xsm:max-w-[52%] min-w-[21rem] mainPageLG:min-w-[24rem]"
                  src={grey}
                  alt="bg"
                />
                <p className="text-black xl:text-white text-center md:text-left font-bold text-[1rem] ssss:text-lg px-4 mainPageLG:text-lg xl:text-2xl py-8 md:px-8 leading-[2.4rem]">
                  {t("mainPage.ourServices.block1")}
                </p>
              </motion.div>
              <motion.div
                custom={2}
                variants={arrAnimation}
                className="flex flex-col mb-8 relative items-center md:flex-row-reverse md:h-[20rem]"
              >
                <img
                  className="h-full max-h-[390px] brightness-50 max-w-full xsm:max-w-[52%] min-w-[21rem] mainPageLG:min-w-[24rem]"
                  src={second}
                  alt="bg"
                />
                <p className="text-black xl:text-white text-center md:text-left font-bold text-[1rem] ssss:text-lg px-4 mainPageLG:text-lg xl:text-2xl py-8 md:px-8 leading-[2.4rem]">
                  {t("mainPage.ourServices.block2")}
                </p>
              </motion.div>
              <motion.div
                custom={3}
                variants={arrAnimation}
                className="flex flex-col mb-8 relative items-center md:flex-row md:h-[20rem]"
              >
                <img
                  className="h-full max-h-[390px] brightness-50 max-w-full xsm:max-w-[52%] min-w-[21rem] mainPageLG:min-w-[24rem]"
                  src={cat}
                  alt="bg"
                />
                <p className="text-black xl:text-white text-center md:text-left font-bold text-[1rem] ssss:text-lg px-4 mainPageLG:text-lg xl:text-2xl py-8 md:px-8 leading-[2.4rem]">
                  {t("mainPage.ourServices.block3")}
                </p>
              </motion.div>
            </div>
            <div>
              <motion.div
                custom={4}
                variants={arrAnimation}
                className="flex flex-col mb-8 relative items-center md:flex-row-reverse md:h-[20rem]"
              >
                <img
                  className="h-full max-h-[390px] brightness-50 max-w-full xsm:max-w-[52%] min-w-[21rem] mainPageLG:min-w-[24rem]"
                  src={dicaprio}
                  alt="bg"
                />
                <p className="text-black text-center md:text-left font-bold text-[1rem] ssss:text-lg px-4 py-8 md:px-8 leading-[2.4rem] xl:text-2xl xl:text-white mainPageLG:text-lg">
                  {t("mainPage.ourServices.block4")}
                </p>
              </motion.div>
              <motion.div
                custom={5}
                variants={arrAnimation}
                className="flex flex-col mb-8  relative items-center md:flex-row md:h-[20rem]"
              >
                <img
                  className="h-full max-h-[390px] brightness-50 max-w-full xsm:max-w-[52%] min-w-[21rem] mainPageLG:min-w-[24rem]"
                  src={whatWeDo}
                  alt="bg"
                />
                <p className="text-black xl:text-white text-center md:text-left font-bold text-[1rem] ssss:text-lg px-4 mainPageLG:text-lg xl:text-2xl py-8 md:px-8 leading-[2.4rem]">
                  {t("mainPage.ourServices.block5")}
                </p>
              </motion.div>
            </div>
          </motion.div>
        </Element>
      </section>
      <section className="py-[3.5rem] xxsm:py-[7.5rem] px-5 bg-[#2d2d2d]">
        <div className="container__standart ">
          <div>
            <motion.h2
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              variants={titleAnimation}
              className="text-center text-white pb-14 text-[2.7rem] mainPageLG:text-[3.75rem]"
            >
              {t("mainPage.agencyDoesnt.title")}
            </motion.h2>
          </div>
          <div className="flex justify-between flex-col items-center ssss:flex-row">
            <RedCross img={redCrossImg} text={t("mainPage.agencyDoesnt.p1")} />
            <RedCross img={redCrossImg} text={t("mainPage.agencyDoesnt.p2")} />
            <RedCross img={redCrossImg} text={t("mainPage.agencyDoesnt.p3")} />
            <RedCross img={redCrossImg} text={t("mainPage.agencyDoesnt.p4")} />
          </div>
        </div>
      </section>
      <section className="py-[3.5rem] xxsm:py-[7.5rem] ">
        <Element name="career" className="container__standart">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={titleAnimation}
            className="text-center"
          >
            {t("mainPage.career.title")}
          </motion.h2>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="flex flex-col xl:flex-row xl:flex-wrap [&>*]:m-5 mt-20"
          >
            <MCareerCard
              custom={1}
              variants={arrAnimation}
              title={t("mainPage.career.admin")}
              text={t("mainPage.career.adminDisc")}
            />
            <MCareerCard
              custom={2}
              variants={arrAnimation}
              title="Team Lead"
              text={t("mainPage.career.teamLeadDisc")}
            />
            <MCareerCard
              custom={3}
              variants={arrAnimation}
              title="Head"
              text={t("mainPage.career.headDisc")}
            />
            <MCareerCard
              custom={4}
              variants={arrAnimation}
              title="Сoach"
              text={t("mainPage.career.coachDisc")}
            />
          </motion.div>
        </Element>
      </section>
      <section>
        <div
          className=" h-[100vh] sectionx"
          style={{
            backgroundImage: `url(${numberBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="container__standart pt-8 ssss:pt-24 px-5">
            <h2 className="text-white text-center">
              {t("mainPage.companyInNumbers.title")}
            </h2>
            <div className="hidden md:flex justify-between [&>*]:w-[24%] mt-[7rem]">
              <div className="text-white">
                <div>
                  <h2 className="text-center">6</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block4")}</h3>
                </div>
              </div>
              <div className="text-white">
                <div>
                  <h2>80%</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block5")}</h3>
                </div>
              </div>
              <div className="text-white">
                <div>
                  <h2>10 370</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block3")}</h3>
                </div>
              </div>
            </div>
            <div className=" margin-top flex flex-col md:flex-row items-center justify-between mt-12 [&>*]:mb-10 [&>*]:w-[70%] md:[&>*]:w-[20%] md:mt-40 md:[&>*]:mb-0">
              <div className="text-white">
                <div>
                  <h2 className="text-center">5</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block1")}</h3>
                </div>
              </div>
              <div className="text-white">
                <div>
                  <h2>135</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block2")}</h3>
                </div>
              </div>
              <div className="text-white">
                <div>
                  <h2>21</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block6")}</h3>
                </div>
              </div>
              <div className="text-white">
                <div>
                  <h2>100%</h2>
                </div>
                <div className="h-[1px] bg-[#a4a4a4] my-3"></div>
                <div>
                  <h3>{t("mainPage.companyInNumbers.block7")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Footer mainColor="#d1b3c5" info={footerInfo} />
      </section>
    </main>
  );
};
