import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EnTranslation from "./locales/EN.json";
import RuTranslation from "./locales/RU.json";
import UaTranslation from "./locales/UA.json";

const local = localStorage.getItem("locale") || "ua";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: EnTranslation },
    ru: { translation: RuTranslation },
    ua: { translation: UaTranslation },
  },
  lng: local,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
