import { useTranslation } from "react-i18next";
import { CareerCardInterface } from "../../interface/CareerCardInterface";
import { useAppDispatch } from "../../store/hooks";
import { setActive, setCareerInfo } from "../../store/slices/modalSlice";
import { TextWithLimit } from "../TextWithLimit/TextWithLimit";
import { ForwardedRef, forwardRef, useState } from "react";
import { motion } from "framer-motion";

export const CareerCard = forwardRef(
  ({ title, text }: CareerCardInterface, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isHover, setIsHover] = useState(false);

    return (
      <div
        ref={ref}
        className="w-[calc(100% - 2.5rem)] xl:w-[30%] max-h-[23rem] p-8 bg-white rounded-lg box-shadow flex flex-col justify-between"
      >
        <h4>{title}</h4>
        <TextWithLimit text={text} limit={80} />
        <div className="flex items-center mt-16 w-fit">
          <button
            id="openModal"
            className={`text-xl ${isHover ? "text-orange-400" : "text-black"}`}
            onClick={() => {
              dispatch(setActive(true));
              dispatch(setCareerInfo({ title, text }));
            }}
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
          >
            {t("mainPage.career.readMore")}
          </button>
          <label
            onMouseEnter={() => {
              setIsHover(true);
            }}
            onMouseLeave={() => {
              setIsHover(false);
            }}
            className={`ml-2 cursor-pointer ${isHover ? " text-orange-400" : "text-black"
              } `}
            htmlFor="openModal"
          >
            &#8921;
          </label>
          {/* <div ></div> */}
        </div>
      </div>
    );
  }
);

export const MCareerCard = motion(CareerCard);
