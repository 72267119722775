import Slider from "react-slick";
import { IBrand, ICarousel } from "./CarouselInterface";
import { Link } from "react-router-dom";
import { log } from "console";

let displayWidth = window.innerWidth;

let slidesToShow

displayWidth <= 1024 ? slidesToShow = 2 : slidesToShow = 3


const settings = {
  infinite: true,
  autoplay: true,
  autoplaySpeed: 1500,
  arrows: false,
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
};




export const Carousel = ({ brands }: ICarousel) => {
  return (
    <div className="overflow-hidden">
      <Slider {...settings}>
        {brands.map((brand: IBrand) => (
          <div>
            <Link
              to={brand.route}
              className="block max-w-[8rem] xsm:max-w-[12rem] m-auto mainPageLG:max-w-[16rem]"
            >
              <img src={brand.logo} alt="logo" />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};
