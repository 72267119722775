import { useState } from "react";
import { ToggleMobMenu } from "../ToggleMobMenu/ToggleMobMenu";
import { IBurgerMenu } from "./BurgerMenuInterface";
import { useTranslation } from "react-i18next";
import { menuType } from "../../types/menuTypes";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLocale } from "../../store/slices/localeSlice";
import { useLocation } from "react-router-dom";
import { setActive } from "../../store/slices/modalSlice";

export const BurgerMenu = ({ showMenu, mainColor, closeNav }: IBurgerMenu) => {
  const [openIndex, setOpenIndex] = useState<null | number>(null);
  const headerBg = useAppSelector((state) => state.headerBg.backgroundColor);
  const lang = useAppSelector((state) => state.locale.localStorageValue);
  const { i18n, t } = useTranslation();
  const titles = t("header", { returnObjects: true }) as Array<menuType>;
  const dispatch = useAppDispatch();
  const location = useLocation();

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setLocale(event.target.value));
    i18n.changeLanguage(event.target.value);
  };

  const toggleMenu = (index: number | null) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      id="myNav"
      className={`bg-black mainPageLG:hidden fixed top-0 bot-0 right-0 pt-[3.25rem] overflow-y-scroll z-[10] transition-all duration-500 h-[100%] w-[100%] ${
        showMenu ? "left-0" : "-left-[103%]"
      }`}
    >
      <div className="relative w-full px-5 mt-20 text-base">
        {location.pathname === "/" ? (
          titles.map((title: menuType, index: number) => {
            return (
              <div key={title.title}>
                <ToggleMobMenu
                  index={index}
                  openIndex={openIndex}
                  toggleMenu={toggleMenu}
                  title={title}
                  closeNav={closeNav}
                />
              </div>
            );
          })
        ) : (
          <>
            <div key={titles[5].title}>
              <ToggleMobMenu
                index={1}
                openIndex={openIndex}
                toggleMenu={toggleMenu}
                title={titles[5]}
                closeNav={closeNav}
              />
            </div>
            <div key={titles[1].title}>
              <ToggleMobMenu
                index={1}
                openIndex={openIndex}
                toggleMenu={toggleMenu}
                title={titles[1]}
                closeNav={closeNav}
              />
            </div>
          </>
        )}
      </div>
      <div className="px-5 flex flex-col">
        <select
          name="lang"
          id="1"
          className={`bg-transparent cursor-pointer outline-none my-10 text-white`}
          onChange={changeLanguage}
          value={lang}
        >
          <option className="text-black" value="en">
            EN
          </option>
          <option className="text-black" value="ua">
            UA
          </option>
          <option className="text-black" value="ru">
            RU
          </option>
        </select>
        <button
          className={`transition__hover bg-[${mainColor}] m-auto w-40 text-white h-9 rounded-lg hover:bg-orange-400`}
          onClick={() => {
            dispatch(setActive(true));
          }}
        >
          {t("headerRequest")}
        </button>
      </div>
    </div>
  );
};
