import { Route, Routes } from "react-router-dom";
import { MainPage } from "../MainPage/MainPage";
import { StarCompany } from "../StarCompany/StarCompany";
import { MediaCompany } from "../MediaCompany/MediaCompany";
import { TakeProfit } from "../TakeProfit/TakeProfit";
import { GeneralMarket } from "../GeneralMarket/GeneralMarket";
import { ModernMarketingofUkraine } from "../ModernMarketingofUkraine/ModernMarketingofUkraine";
import { ATech } from "../ATech/ATech";
import { SalesConsult } from "../SalesConsult/SalesConsult";
import { PulseMarketing } from "../PulseMarketing/PulseMarketing";

export const PagesRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/star-company" element={<StarCompany />} />
      <Route path="/media-company" element={<MediaCompany />} />
      <Route path="/take-profit" element={<TakeProfit />} />
      <Route path="/modern-marketing-of-ukraine" element={<ModernMarketingofUkraine />} />
      <Route path="/general-market" element={<GeneralMarket />} />
      <Route path="/a-tech" element={<ATech />} />
      <Route path="/sales-consult" element={<SalesConsult />} />
      <Route path="/pulse-marketing" element={<PulseMarketing />} />
    </Routes>
  );
};
