import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { setLocale } from "../../store/slices/localeSlice";
import { Link } from "react-scroll";
import { setBg } from "../../store/slices/headerBgSlice";
import { NavLink, useLocation } from "react-router-dom";
import { MobileMenuButton } from "../MobileMenuBtn/MobileMenuBtn";
import { IHeader } from "./HeaderInterface";
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";
import { menuType } from "../../types/menuTypes";

export const Header = ({ mainColor, imgSrc, branchRoute }: IHeader) => {
  const [showMenu, setShowMenu] = useState(false);
  const headerBg = useAppSelector((state) => state.headerBg.backgroundColor);
  const lang = useAppSelector((state) => state.locale.localStorageValue);
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const titles = t("header", { returnObjects: true }) as Array<menuType>;
  const isDesktop = screenWidth > 1100;

  const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setLocale(event.target.value));
    i18n.changeLanguage(event.target.value);
  };

  const closeNav = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 50;

      if (scrollTop > scrollThreshold) {
        dispatch(setBg("bg-white"));
      } else {
        dispatch(setBg("bg-transparent"));
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMenu]);

  return (
    <header
      className={`h-0 sticky top-0 left-0 z-50 ${
        headerBg === "bg-transparent" ? "text-white" : "text-black"
      }`}
    >
      <div
        className={`transition__hover px-8 ${headerBg} flex justify-between items-center headerAdaptive:px-[3.125rem]`}
      >
        {isDesktop ? (
          <div className="z-[20] w-[6.313rem]  xl:w-[11.313rem] headerAdaptive:w-[13.313rem]">
            <NavLink
              to={branchRoute}
              className="w-[6.25rem] inline-block"
              onClick={closeNav}
            >
              <img
                src={imgSrc}
                alt="Emblem"
                className="w-[6.25rem] h-[6.25rem]"
              />
            </NavLink>
          </div>
        ) : (
          <div className="z-[20] w-full flex justify-between items-center">
            <div className="z-[20] w-[6.875rem]">
              <NavLink to={branchRoute} onClick={closeNav}>
                <img src={imgSrc} alt="Emblem" className="w-full" />
              </NavLink>
            </div>
            <MobileMenuButton showMenu={showMenu} setShowMenu={setShowMenu} />
          </div>
        )}
        <nav>
          {location.pathname === "/" ? (
            <ul className="hidden header-ul text-lg	[&>*]:mx-2 [&>*]:px-2 tables:flex xll:text-2xl">
              <li className="hover:text-orange-400">
                <Link
                  activeClass="active"
                  to="aboutUS"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  {titles[0].title}
                </Link>
              </li>
              <li className="group hover:text-orange-400">
                <Link
                  activeClass="active"
                  to="branches"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  {titles[1].title}
                </Link>
                <ul
                  className={`z-[100] font-semibold invisible pr-8 py-2 min-w-[18.75rem] absolute top-[4rem] transition-all opacity-0 group-hover:visible group-hover:opacity-100 group-hover:translate-y-2 ease-in duration-200 rounded shadow-menuBlockShadow ${
                    headerBg === "bg-transparent"
                      ? "bg-black !text-white"
                      : "bg-white !text-black"
                  }`}
                >
                  <li>
                    <NavLink
                      to="/star-company"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("StarCompany.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/media-company"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("MediaCompany.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/take-profit"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("TakeProfit.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/modern-marketing-of-ukraine"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("ModernMarketingofUkraine.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/general-market"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("GeneralMarket.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/a-tech"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("ATech.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sales-consult"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("SalesConsult.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/pulse-marketing"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("PulseMarketing.name")}
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="hover:text-orange-400">
                <Link
                  activeClass="active"
                  to="ourServices"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  {titles[2].title}
                </Link>
              </li>
              <li className="hover:text-orange-400">
                <Link
                  activeClass="active"
                  to="career"
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  {titles[3].title}
                </Link>
              </li>
              <li className="hover:text-orange-400">
                <Link
                  activeClass="active"
                  to="contact-us"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  {titles[4].title}
                </Link>
              </li>
            </ul>
          ) : (
            <ul className="header-ul hidden text-lg [&>*]:mx-2 [&>*]:px-2 tables:flex xll:text-2xl">
              <li className="hover:text-orange-400">
                <NavLink to="/"> {titles[5].title}</NavLink>
              </li>
              <li className="group hover:text-orange-400">
                <Link
                  activeClass="active"
                  to="branches"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                >
                  {titles[1].title}
                </Link>
                <ul
                  className={`z-[100] font-semibold invisible pr-8 py-2 min-w-[18.75rem] absolute top-[4rem] transition-all opacity-0 group-hover:visible group-hover:opacity-100 group-hover:translate-y-2 ease-in duration-200 rounded shadow-menuBlockShadow ${
                    headerBg === "bg-transparent"
                      ? "bg-black !text-white"
                      : "bg-white !text-black"
                  }`}
                >
                  <li>
                    <NavLink
                      to="/star-company"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("StarCompany.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/media-company"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("MediaCompany.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/take-profit"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("TakeProfit.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/modern-marketing-of-ukraine"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("ModernMarketingofUkraine.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/general-market"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("GeneralMarket.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/a-tech"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("ATech.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sales-consult"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("SalesConsult.name")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/pulse-marketing"
                      className="block px-2.5 py-2 hover:!text-orange-400 ease-in-out duration-100"
                    >
                      {t("PulseMarketing.name")}
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          )}
          <BurgerMenu
            showMenu={showMenu}
            mainColor={mainColor}
            closeNav={closeNav}
          />
        </nav>
        <div>
          <button
            className={`transition__hover hidden bg-[${mainColor}] w-40 text-white h-9 rounded-lg hover:bg-orange-400 tables:inline-block`}
            onClick={() => {
              dispatch(setActive(true));
            }}
          >
            {t("headerRequest")}
          </button>
          <select
            name="lang"
            id="1"
            className={`bg-transparent ml-6 headerAdaptive:ml-10 hidden font-medium cursor-pointer outline-none tables:inline-block ${
              headerBg === "bg-transparent" ? "text-white" : "text-black"
            }`}
            onChange={changeLanguage}
            value={lang}
          >
            <option className="text-black" value="en">
              EN
            </option>
            <option className="text-black" value="ua">
              UA
            </option>
            <option className="text-black" value="ru">
              RU
            </option>
          </select>
        </div>
      </div>
    </header>
  );
};
