import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import { phoneRegExp } from "../../constants/Regs";
import Instagram from "../../images/GeneralImages/instagram.svg";
import Telegram from "../../images/GeneralImages/telegram.svg";
import Viber from "../../images/GeneralImages/viber.svg";
import { Element } from "react-scroll";
import { IFooter } from "./FooterInterface";
import { Loader } from "../Loader/Loader";
import { Modal } from "../Modal/Modal";

export const Footer = ({ mainColor, info }: IFooter) => {
  const [isSuccess, setSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, t("formsWarnings.tooShort"))
      .max(50, t("formsWarnings.tooLong"))
      .required(t("formsWarnings.symbols")),
    PhoneNumber: Yup.string()
      .matches(phoneRegExp, t("formsWarnings.phoneNumber"))
      .required(t("formsWarnings.enterPhoneNumber")),
    Email: Yup.string()
      .email(t("formsWarnings.invalidEmail"))
      .required(t("formsWarnings.incorrectEmailFormat")),
  });

  return (
    <footer className="bg-[#fffff] w-full">
      <div className="flex justify-center w-full">
        <div className="grid grid-cols-1 gap-x-[3rem] w-[100%] max-w-[1200px] py-[3rem] px-[2.5rem] mainPageLG:w-[75%] md:grid-cols-2 md:py-[6rem]">
          <div className="col-1">
            <Element
              name="contact-us"
              className="flex items-center justify-between"
            >
              <p
                className={`text-xl font-bold bg-[${mainColor}] px-2.5 xl:text-3xl xxxsm:text-2xl`}
              >
                {t("requestForm.title")}
              </p>
            </Element>
            <div className="mt-5">
              <Formik
                initialValues={{
                  Name: "",
                  PhoneNumber: "",
                  Email: "",
                  DestinationAddress: info.email,
                  Message: "",
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={(values, { resetForm }) => {
                  setInProgress(true);
                  setSuccess(false);
                  axios
                    .post(
                      "https://hrl36y2im5gafzxkzetqvdazxy0nzmkl.lambda-url.us-east-1.on.aws/api/email/contact-us",
                      values,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((response) => {
                      console.log("Success:", response.data);
                      setSuccess(true);
                      setInProgress(false);
                      resetForm();
                    })
                    .catch((error) => {
                      console.error("Something went wrong", error);
                      setSuccess(false);
                      setInProgress(false);
                    });
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <article className="mb-[2rem]">
                      <div className="mb-[1.563rem]">
                        <div className="relative">
                          <label htmlFor="Name" className="form-label">
                            {t("requestForm.firstName")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="Name"
                            name="Name"
                            type="text"
                            className={`form-input shadow-inputShadow ${touched.Name && errors.Name && "!border-[#eb5757]"
                              }`}
                          />
                          {touched.Name && errors.Name && (
                            <span className="form-req-info">{errors.Name}</span>
                          )}
                        </div>
                      </div>
                      <div className="mb-[1.563rem]">
                        <div className="relative">
                          <label htmlFor="PhoneNumber" className="form-label">
                            {t("requestForm.phone")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="PhoneNumber"
                            name="PhoneNumber"
                            type="tel"
                            className={`form-input shadow-inputShadow ${touched.PhoneNumber &&
                              errors.PhoneNumber &&
                              "!border-[#eb5757]"
                              }`}
                          />
                          {touched.PhoneNumber && errors.PhoneNumber && (
                            <span className="form-req-info">
                              {errors.PhoneNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mb-[1.563rem]">
                        <div className="relative">
                          <label htmlFor="Email" className="form-label">
                            {`${t("requestForm.email")} email`}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="Email"
                            name="Email"
                            type="text"
                            className={`form-input shadow-inputShadow ${touched.Email &&
                              errors.Email &&
                              "!border-[#eb5757]"
                              }`}
                          />
                          {touched.Email && errors.Email && (
                            <span className="form-req-info">
                              {errors.Email}
                            </span>
                          )}
                        </div>
                      </div>
                    </article>
                    <article className="mb-[2rem]">
                      <label htmlFor="Message" className="form-label">
                        {t("requestForm.messange")}
                      </label>
                      <Field
                        as="textarea"
                        rows="40"
                        name="Message"
                        id="Message"
                        className="form-input shadow-inputShadow !h-20 resize-none"
                      />
                    </article>
                    <div className="relative">
                      {inProgress ? (
                        <Loader height="41" width="100%" color={mainColor} />
                      ) : (
                        <button
                          type="submit"
                          className={`btn-style bg-[${mainColor}] text-[0.9rem] font-bold block mt-[1.875rem] rounded-[0.3rem] min-w-[6.25rem] !max-w-[16rem] px-[1.875rem] py-[15px] hover:text-white duration-500`}
                        >
                          {isSuccess
                            ? t("requestForm.successfully")
                            : t("requestForm.button")}
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="col-2 ml-0 mt-[3rem] md:ml-[3.125rem] md:mt-0 ">
            <div className="flex items-center justify-between">
              <div
                className={`text-xl font-bold mb-[1.6rem] bg-[${mainColor}] px-2.5 xl:text-3xl xxxsm:text-2xl`}
              >
                {t("footer.ourContacts")}
              </div>
            </div>
            {info.email !== "" ? (
              <div className="mb-[1.6rem]">
                <div className="font-bold text-lg mb-[0.5rem] md:text-xl">
                  {t("footer.email")}
                </div>
                <a href={`mailto: ${info.email}`}>{info.email}</a>
              </div>
            ) : null}
            {info.phoneNumber !== "" ? (
              <div className="mb-[1.6rem]">
                <div className="font-bold text-lg mb-[0.5rem] md:text-xl">
                  {t("footer.callUs")}
                </div>
                <a href={`tel:${info.phoneNumber}`}>{info.phoneNumber}</a>
              </div>
            ) : null}
            <div className="mb-[1.6rem]">
              <div className="font-bold text-lg mb-[0.5rem] md:text-xl">
                {t("footer.socialLinks")}
              </div>
              <div className="flex flex-row gap-x-[1rem]">
                {info.inst !== "" ? (
                  <a
                    href={`https://www.instagram.com/${info.inst}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`socials-btn bg-[${mainColor}] hover:scale-110 transition-all ease-in-out duration-200 `}
                  >
                    <img src={Instagram} alt="Instagram" className="w-[60%]" />
                  </a>
                ) : null}
                {info.telega !== "" ? (
                  <a
                    href={`https://t.me/${info.telega}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`socials-btn bg-[${mainColor}] hover:scale-110 transition-all ease-in-out duration-200`}
                  >
                    <img
                      src={Telegram}
                      alt="Telegram"
                      className="w-[55%] mr-[2px]"
                    />
                  </a>
                ) : null}
                {info.viber?.length ? (
                  <a
                    href={`viber://add?number=38${info.viber}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`socials-btn bg-[${mainColor}] hover:scale-110 transition-all ease-in-out duration-200`}
                  >
                    <img src={Viber} alt="Telegram" className="w-[55%]" />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center px-[2.5rem] py-[2rem] md:py-[4rem]">
        {t("footer.copyrights")}
      </div>
      <Modal email={info.email} mainColor={mainColor} />
    </footer>
  );
};
