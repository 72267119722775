import { ITextWithLimit } from "./TextWithLimitInterface";

export const TextWithLimit = ({ text, limit }: ITextWithLimit) => {
  return (
    <div>
      <p className="text-[0.9rem] xxsm:text-[1rem] ssss:text-[1.1rem] mt-5">
        {text.slice(0, limit)}
        {text.length > limit && "..."}
      </p>
    </div>
  );
};
