import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive, setCareerInfo } from "../../store/slices/modalSlice";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../constants/Regs";
import axios from "axios";
import Cross from "../../images/GeneralImages/cross.png";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { tabsAnimation } from "../../animations";
import { Loader } from "../Loader/Loader";
import { IModal } from "./ModalInterface";

export const Modal = ({ email, mainColor }: IModal) => {
  //   const lang = useAppSelector((state) => state.locale);
  const isActive = useAppSelector((state) => state.modal.active);
  const careerInfo = useAppSelector((state) => state.modal.careerInfo);
  const [isSuccess, setSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const body = document.body;

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, t("formsWarnings.tooShort"))
      .max(50, t("formsWarnings.tooLong"))
      .required(t("formsWarnings.symbols")),
    PhoneNumber: Yup.string()
      .matches(phoneRegExp, t("formsWarnings.phoneNumber"))
      .required(t("formsWarnings.enterPhoneNumber")),
    Email: Yup.string()
      .email(t("formsWarnings.invalidEmail"))
      .required(t("formsWarnings.incorrectEmailFormat")),
  });

  useEffect(() => {
    isActive
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "auto");
  }, [isActive]);

  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={tabsAnimation}
          className="h-screen w-screen fixed top-0 left-0 bg-[rgba(0,0,0,0.6)] z-[100] flex items-center justify-center"
        >
          <div className="p-[2rem] sm:p-[3rem] bg-white max-h-[70vh] overflow-auto">
            <div className="flex items-center	justify-between">
              <p
                className={`text-[1.2rem] sm:text-[1.8rem] max-w-[17rem] sm:max-w-[30.125rem] font-semibold text-black bg-[${mainColor}] px-2.5 mr-3`}
              >
                {careerInfo?.title.length
                  ? careerInfo.title
                  : t("requestForm.title")}
              </p>
              <div>
                <img
                  src={Cross}
                  alt="cross"
                  className="w-5 cursor-pointer hover:scale-125 duration-300"
                  onClick={() => {
                    setSuccess(false);
                    dispatch(setActive(false));
                    dispatch(setCareerInfo({ title: "", text: "" }));
                  }}
                />
              </div>
            </div>
            <div className="mt-5">
              {careerInfo?.text.length ? (
                <p className="max-w-[17rem] sm:max-w-[30.125rem] sm: m-auto font-semibold leading-8">
                  {careerInfo.text}
                </p>
              ) : (
                <Formik
                  initialValues={{
                    Name: "",
                    PhoneNumber: "",
                    Email: "",
                    DestinationAddress: email,
                    Message: "",
                  }}
                  validationSchema={DisplayingErrorMessagesSchema}
                  onSubmit={(values, { resetForm }) => {
                    setInProgress(true);
                    setSuccess(false);
                    axios
                      .post(
                        "https://w5z5vcgwdvh6ksokywe3j35fvy0ajmsg.lambda-url.us-east-1.on.aws/api/forms/lets-talk",
                        values,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then((response) => {
                        console.log("Success:", response.data);
                        setSuccess(true);
                        setInProgress(false);
                        resetForm();
                      })
                      .catch((error) => {
                        console.error("Something went wrong", error);
                        setSuccess(false);
                        setInProgress(false);
                      });
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <article className="mb-[2rem]">
                        <div className="mb-[1.563rem]">
                          <div className="relative">
                            <label htmlFor="FullName" className="form-label">
                              {t("requestForm.firstName")}
                              <span className="text-[#eb5757]">*</span>
                            </label>
                            <Field
                              id="FullName"
                              name="FullName"
                              type="text"
                              className={`form-input shadow-inputShadow ${
                                touched.Name &&
                                errors.Name &&
                                "!border-[#eb5757]"
                              }`}
                            />
                            {touched.Name && errors.Name && (
                              <span className="form-req-info">
                                {errors.Name}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mb-[1.563rem]">
                          <div className="relative">
                            <label htmlFor="PhoneNumber" className="form-label">
                              {t("requestForm.phone")}
                              <span className="text-[#eb5757]">*</span>
                            </label>
                            <Field
                              id="PhoneNumber"
                              name="PhoneNumber"
                              type="tel"
                              className={`form-input shadow-inputShadow ${
                                touched.PhoneNumber &&
                                errors.PhoneNumber &&
                                "!border-[#eb5757]"
                              }`}
                            />
                            {touched.PhoneNumber && errors.PhoneNumber && (
                              <span className="form-req-info">
                                {errors.PhoneNumber}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="mb-[1.563rem]">
                          <div className="relative">
                            <label htmlFor="Email" className="form-label">
                              {t("requestForm.email")} email
                              <span className="text-[#eb5757]">*</span>
                            </label>
                            <Field
                              id="Email"
                              name="Email"
                              type="text"
                              className={`form-input shadow-inputShadow ${
                                touched.Email &&
                                errors.Email &&
                                "!border-[#eb5757]"
                              }`}
                            />
                            {touched.Email && errors.Email && (
                              <span className="form-req-info">
                                {errors.Email}
                              </span>
                            )}
                          </div>
                        </div>
                      </article>
                      <article className="mb-[2rem]">
                        <label htmlFor="Message" className="form-label">
                          {t("requestForm.messange")}
                        </label>
                        <Field
                          as="textarea"
                          rows="40"
                          name="Message"
                          id="Message"
                          className="form-input shadow-inputShadow !h-20 resize-none"
                        />
                      </article>
                      <div className="relative">
                        {inProgress ? (
                          <Loader height="51" width="100%" color={mainColor} />
                        ) : (
                          <button
                            type="submit"
                            className={`btn-style bg-[${mainColor}] text-[0.9rem] font-bold block mt-[1.875rem] rounded-[0.3rem] min-w-[6.25rem] px-[1.875rem] py-[15px] hover:text-white duration-500`}
                          >
                            {isSuccess
                              ? t("requestForm.successfully")
                              : t("requestForm.button")}
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
