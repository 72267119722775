import { motion } from "framer-motion";
import { RedCrossInterface } from "../../interface/RedCrossInterface";
import { animationLogo } from "../../animations";

export const RedCross = ({ img, text }: RedCrossInterface) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.1, once: true }}
      className="w-full xsm:w-[70%] ssss:w-[23%] flex flex-col justify-between items-center"
    >
      <motion.img
        variants={animationLogo}
        className="w-[6.25rem] h-[6.25rem] mb-5"
        src={img}
        alt="#"
      />
      <h3 className="text-white font-medium h-[7.063rem] text-center mainPageLG:font-extrabold">
        {text}
      </h3>
    </motion.div>
  );
};
