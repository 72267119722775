export const mainAnimation = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: { duration: 0.6 },
  },
};

export const titleAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 40 },
  },
};

export const arrAnimation = {
  hidden: {
    y: 25,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.3 },
  }),
};

export const animationLogo = {
  hidden: { opacity: 0, scale: 0.5, rotateY: 360, translateZ: -1000 },
  visible: {
    opacity: 1,
    scale: 1,
    rotateY: 0,
    translateZ: 0,
    transition: { duration: 1, ease: "easeInOut" },
  },
};

export const branchAnimationLeft = {
  hidden: {
    x: 170,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.8 },
  },
};

export const branchAnimationRight = {
  hidden: {
    x: -170,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 0.8 },
  },
};

export const tabsAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};
